import { useQuery, useMutation, useQueryClient } from "@tanstack/vue-query";
import { meetingService, authenticationService } from "@/core/services";
import { useMeetingId } from "./routes";
import { DateHelpers } from "@sgdf/ui-library";
import { ref } from "vue";
import { type NotNestedMeetingRole } from "@sgdf/client";

const getParticipantKey = (meetingId: Ref<string | null>) => computed(() => [
  "meetings",
  meetingId.value,
  "participants",
]);

export const useCurrentMeeting = (isEnabled?: ComputedRef<boolean>) => {
  const meetingId = useMeetingId();
  return useQuery({
    queryKey: computed(() => ["meetings", meetingId.value]),
    queryFn: () =>
      meetingService
        .getInstance()
        .getMeetingsApi()
        .meetingsRetrieve({ uuid: meetingId.value! }),
    enabled: isEnabled ?? true,
    staleTime: Infinity,
  });
};

export const usePublicMeeting = () => {
  const meetingId = useMeetingId();
  return useQuery({
    queryKey: computed(() => ["public-meetings", meetingId.value]),
    queryFn: () =>
      meetingService
        .getInstance()
        .getMeetingsApi()
        .meetingsPublicRetrieve({ uuid: meetingId.value! }),
    staleTime: Infinity,
  });
};

export function useMeetingTypes() {
  return useQuery({
    queryKey: ["meeting-types"],
    queryFn: () =>
      meetingService.getInstance().getMeetingsApi().meetingTypesList(),
    staleTime: Infinity,
  });
}

export function useMeetings() {
  return useQuery({
    queryKey: ["meetings"],
    queryFn: () => meetingService.getInstance().getMeetingsApi().meetingsList(),
  });
}

export const useIsActive = () => {
  const currentDate = new Date();
  const isActive = ref<boolean>(false);

  const { data } = usePublicMeeting();

  watch(data, () => {
    const startDate = data.value?.start_date;
    const endDate = data.value?.end_date;

    if (startDate && endDate) {
      isActive.value = DateHelpers.isBetween(
        new Date(startDate),
        new Date(endDate),
        currentDate,
      );
    }
  });

  return isActive;
};

export const useMeetingRole = async () => {
  const meetingId = useMeetingId();

  const role = ref<NotNestedMeetingRole | null>(null);

  const user = await authenticationService.getUser();
  const currentParticipation = user?.participations.find(
    (p) => p.meeting === meetingId.value,
  );

  if (currentParticipation) {
    role.value = currentParticipation.role;
  }
  return role;
};

export function useResetSignatures() {
  const meetingId = useMeetingId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () =>
      meetingService
        .getInstance()
        .getMeetingsApi()
        .meetingsResetParticipantsSignatureDestroy({ uuid: meetingId.value! }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getParticipantKey(meetingId),
      });
    },
  });
}

export function useSyncMeetingMutation(
  onSuccess?: () => void,
  onError?: () => void,
) {
  const queryClient = useQueryClient();

  const meetingId = useMeetingId();
  return useMutation({
    mutationFn: () =>
      meetingService
        .getInstance()
        .getMeetingsApi()
        .meetingsSyncFromIntranetCreate({
          uuid: meetingId.value!,
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: computed(() => ["meetings", meetingId]) });
      queryClient.invalidateQueries({
        queryKey: getParticipantKey(meetingId),
      });
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      if (onError) {
        onError();
      }
    },
  });
}
